import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/view",
    name: "View",
    component: () => import("@/views/confirm-design/CustomConfirmDesign"),
  },
  {
    path: "/form",
    name: "Form",
    component: () => import("@/views/custom-form/CustomForm"),
  },
  {
    path: "/preview",
    name: "Preview",
    component: () => import("@/views/custom-form/CustomForm"),
  },
  {
    path: "/aftermarket",
    name: "AftermarketForm",
    component: () => import("@/views/aftermarket/AftermarketForm"),
  },
  {
    path: "/aftermarketPreview",
    name: "AftermarketPreview",
    component: () => import("@/views/aftermarket/AftermarketForm"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/PageNotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
