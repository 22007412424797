import { createApp } from "vue"
import App from "./App.vue"
import {
  Button,
  Swipe,
  SwipeItem,
  Image,
  Col,
  Row,
  Dialog,
  ImagePreview,
  Toast,
  Popup,
  Overlay,
  Loading,
  BackTop,
  Field
} from "vant"
import "vant/lib/index.css"
import router from "@/router/index.js"
import VueCropper from "vue-cropper"
import "vue-cropper/dist/index.css"

let app = createApp(App)
app.use(VueCropper)
app.use(router)
app.use(Button)
app.use(Swipe)
app.use(SwipeItem)
app.use(Image)
app.use(Col)
app.use(Row)
app.use(Dialog)
app.use(ImagePreview)
app.use(Toast)
app.use(Popup)
app.use(Overlay)
app.use(Loading)
app.use(BackTop)
app.use(Field)

app.mount("#app")
